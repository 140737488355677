// Generated by Framer (1119174)

import { addFonts, cx, CycleVariantState, getFonts, resolveLink, useLocaleInfo, useRouter, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import HeaderMenuItem from "./JMWkmIIwU";
const HeaderMenuItemFonts = getFonts(HeaderMenuItem);

const cycleOrder = ["Rwfdmw5dA"];

const serializationHash = "framer-rLbZG"

const variantClassNames = {Rwfdmw5dA: "framer-v-7qe94p"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Rwfdmw5dA", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const router = useRouter()

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-rLbZG", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-7qe94p", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Rwfdmw5dA"} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-qks9us-container"} layoutDependency={layoutDependency} layoutId={"hXWx4ONCv-container"}><HeaderMenuItem arrow={false} color={"var(--token-0c8fd507-faea-491a-8caf-ad6cd40c959b, rgb(40, 40, 42)) /* {\"name\":\"Neutral / 07\"} */"} height={"100%"} id={"hXWx4ONCv"} layoutId={"hXWx4ONCv"} link={resolveLink({webPageId: "QQwekMhSz"}, router)} title={"Construction"} variant={"WG1EkjXmD"} width={"100%"}/></motion.div><motion.div className={"framer-ib1y36-container"} layoutDependency={layoutDependency} layoutId={"mQeLmRtTH-container"}><HeaderMenuItem arrow={false} color={"var(--token-0c8fd507-faea-491a-8caf-ad6cd40c959b, rgb(40, 40, 42)) /* {\"name\":\"Neutral / 07\"} */"} height={"100%"} id={"mQeLmRtTH"} layoutId={"mQeLmRtTH"} link={resolveLink({webPageId: "augiA20Il"}, router)} title={"Supply Chain"} variant={"WG1EkjXmD"} width={"100%"}/></motion.div><motion.div className={"framer-o76fvb-container"} layoutDependency={layoutDependency} layoutId={"XitUDugR2-container"}><HeaderMenuItem arrow={false} color={"var(--token-0c8fd507-faea-491a-8caf-ad6cd40c959b, rgb(40, 40, 42)) /* {\"name\":\"Neutral / 07\"} */"} height={"100%"} id={"XitUDugR2"} layoutId={"XitUDugR2"} link={resolveLink({webPageId: "Ya6vHDQb0"}, router)} title={"Manufacturing"} variant={"WG1EkjXmD"} width={"100%"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-rLbZG [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-rLbZG .framer-1jk8an1 { display: block; }", ".framer-rLbZG .framer-7qe94p { align-content: start; align-items: start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 18px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-rLbZG .framer-qks9us-container, .framer-rLbZG .framer-ib1y36-container, .framer-rLbZG .framer-o76fvb-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-rLbZG .framer-7qe94p { gap: 0px; } .framer-rLbZG .framer-7qe94p > * { margin: 0px; margin-bottom: calc(18px / 2); margin-top: calc(18px / 2); } .framer-rLbZG .framer-7qe94p > :first-child { margin-top: 0px; } .framer-rLbZG .framer-7qe94p > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 108
 * @framerIntrinsicWidth 105
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 */
const FramerSU6U_MIyh: React.ComponentType<Props> = withCSS(Component, css, "framer-rLbZG") as typeof Component;
export default FramerSU6U_MIyh;

FramerSU6U_MIyh.displayName = "Menu";

FramerSU6U_MIyh.defaultProps = {height: 108, width: 105};

addFonts(FramerSU6U_MIyh, [...HeaderMenuItemFonts])